import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import typography from '../../typography';

export const Item = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 40px;

  /* IE fix */
  @media all and (-ms-high-contrast: none) {
    > * {
      max-width: 100%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 50%;
    width: 50%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    max-width: 33.3333%;
    width: 33.3333%;
    padding: 0 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 22px;

  /* IE fix */
  @media all and (-ms-high-contrast: none) {
    position: relative;
    height: 0;
    padding-bottom: ${({ type }) => (type === 'default' ? '52.67%' : '51.02%')};

    > svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: 0 auto;
    }
  }
`;

export const Date = styled.div`
  margin-bottom: 5px;
  font-family: AdelleSansLight;
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.greyDarkest};
`;

export const Label = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 0 16px;
  font-family: AdelleSansLight;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  line-height: 30px;
  background-color: ${(props) => props.theme.colors.brand};
  border-radius: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    right: 40px;
  }
`;

export const Title = styled.h4`
  ${typography.headerMedium};
  margin-top: 0;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  font-family: AdelleSansLight;
  color: ${(props) => props.theme.colors.greyDark};
  line-height: 34.4px;

  p {
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    line-height: 32px;
  }
`;

export const Button = styled(AniLink)`
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.brand};
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  svg {
    margin-left: 20px;
    width: 18px;
    fill: ${(props) => props.theme.colors.brand};
  }
`;
