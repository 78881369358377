import Theme from './theme';

export function getColor(index) {
  const colors = [
    Theme.colors.pink,
    Theme.colors.blue,
    Theme.colors.turquoise,
    Theme.colors.orange,
    Theme.colors.purple,
    Theme.colors.green,
  ];

  return colors[index + (1 % 6)];
}

export function getColorSet(colors) {
  if (colors) {
    const splitColors = colors.split(' - ');
    const primaryColor = splitColors[0].toLowerCase();
    const secondaryColor = splitColors[1].toLowerCase();

    return {
      primary: Theme.colors[primaryColor],
      secondary: Theme.colors[secondaryColor],
    };
  }

  return {
    primary: Theme.colors.red,
    secondary: Theme.colors.blue,
  };
}
