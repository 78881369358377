import styled from 'styled-components';
import Typography from '../../typography';
import Picture from '../Picture/Picture';
import Container from '../Container';

export const StyledH2 = styled.h2`
  ${Typography.h4}
  margin: 0 0 20px;
  color: ${(props) => props.theme.colors.black};
`;

export const StyledH3 = styled.h3`
  ${Typography.h5}
  margin: 0 0 20px;
  color: ${(props) => props.theme.colors.black};
`;

export const Description = styled.div`
  p {
    margin: 0 0 30px;
    font-family: AdelleSansLight;
    font-size: 18px;
    line-height: 32px;
    color: ${(props) => props.theme.colors.greyDarkest};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Image = styled(Picture)`
  width: 100%;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    border-radius: 32px;
  }
`;

export const ParagraphContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  &.paragraph-mirrored {
    flex-direction: column-reverse;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: auto;
    flex-direction: row;
    align-items: center;

    &.paragraph-mirrored {
      flex-direction: row-reverse;
    }
  }
`;

export const ParagraphContent = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 50%;
    padding: 0 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 50%;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;
