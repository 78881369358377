/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import uuid from 'react-uuid';
import { useMedia } from 'react-use-media';
import Theme from '../../theme';

import { Svg } from './styles';

const Mask = ({
  variant = 'default',
  resize = true,
  gradient,
  image,
  backgroundColor,
  ...rest
}) => {
  const id = uuid();
  const img = useRef(new Image());
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const xxl = useMedia({
    minWidth: Theme.breakpoints.xxl,
  });
  const xl = useMedia({
    minWidth: Theme.breakpoints.xl,
    maxWidth: Theme.breakpoints.xxl,
  });
  const l = useMedia({
    minWidth: Theme.breakpoints.l,
    maxWidth: Theme.breakpoints.xl,
  });
  const m = useMedia({
    minWidth: Theme.breakpoints.m,
    maxWidth: Theme.breakpoints.l,
  });
  const s = useMedia({
    minWidth: Theme.breakpoints.s,
    maxWidth: Theme.breakpoints.m,
  });

  const viewbox = () => {
    if (variant.includes('bubble')) {
      return {
        width: '640',
        height: '640',
        value: '0 0 640 640',
      };
    }

    switch (variant) {
      case 'vertical':
        return {
          width: '1160',
          height: '768',
          value: '0 0 768 1160',
        };
      case 'horizontal':
        return {
          width: '1215',
          height: '620',
          value: '0 0 1215 620',
        };
      default:
        return {
          width: '1215',
          height: '640',
          value: '0 0 1215 640',
        };
    }
  };

  const path = () => {
    switch (variant) {
      case 'horizontal':
        return 'M1183 0C1200.67 0 1215 14.31 1215 31.95C1215 73.24 1215 403.56 1215 444.85C1215 461.02 1202.9 474.64 1186.82 476.57C1071.72 490.39 150.92 600.95 35.82 614.77C18.27 616.88 2.34 604.38 0.23 586.86C0.08 585.59 0 584.32 0 583.04C0 527.94 0 87.06 0 31.95C0 14.31 14.33 0 32 0C262.2 0 1067.9 0 1183 0Z';
      case 'vertical':
        return 'M721.29 0C747.09 0 768 20.92 768 46.72C768 230.16 768 737.73 768 922.16C768 946.82 751.51 968.43 727.72 974.95C582.18 1014.82 200.32 1119.42 58.4 1158.29C29 1166.35 0 1144.22 0 1113.74C0 890.99 0 270.25 0 44.24C0 19.81 19.8 0 44.24 0C188.99 0 577.03 0 721.29 0Z';
      case 'bubble1':
        return 'M0.37 315.52C-3.24 231.57 19.83 164.39 69.81 113.73C120.01 63.05 191.58 34.49 284.97 27.97C384.93 20.99 467.22 44.73 532.06 99.18C596.7 153.63 632.42 227.78 639.01 321.92C644.92 406.18 624.39 474.1 577.67 525.49C530.7 576.87 463.26 605.62 375.29 611.77C292.12 617.56 221.62 605.98 163.8 577.24C105.97 548.49 64.16 504.25 38.75 443.97C14.93 391.41 4.19 349.08 0.37 315.52';
      case 'bubble2':
        return 'M168.44 592.69C237.56 636.63 305.47 649.97 372.5 632.67C439.65 615.18 498.51 568.93 549.32 493.61C603.72 412.98 623.63 332.24 609.15 251.18C594.54 170.3 549.26 104.13 472.99 52.71C404.73 6.67 337.42 -9.18 271.34 5.05C205.19 19.48 148.16 62.16 100.3 133.12C55.02 200.2 30.59 265.13 26.8 327.73C23.01 390.37 40.07 447.07 78.62 497.82C111.44 543.46 141.96 573.12 168.44 592.69';
      case 'bubble3':
        return 'M0.36 315.52C-3.23 231.64 19.85 164.51 69.81 113.88C120.01 63.24 191.58 34.68 284.96 28.16C384.92 21.19 467.21 44.91 532.06 99.33C596.7 153.74 632.41 227.86 639.01 321.91C644.92 406.12 624.39 474.01 577.66 525.36C530.7 576.7 463.25 605.44 375.3 611.57C292.12 617.37 221.62 605.8 163.8 577.07C105.97 548.34 64.16 504.12 38.75 443.89C14.94 391.35 4.19 349.06 0.36 315.52';
      case 'bubbleSpeech1':
        return 'M46.45 232.52C63.14 146.67 98.98 83.62 153.99 43.34C208.97 3.3 276.6 -9.05 356.62 6.51C446 23.88 512.68 67.6 556.93 137.52C601.14 207.63 613.77 291.37 594.81 388.93C577.09 480.08 540.91 547.22 486.17 590.76C431.48 634.08 364.28 649.02 284.28 635.77C252.58 628.13 213.35 612.66 165.89 583.14C159.5 579.4 153.4 575.42 147.49 571.29C136.84 571.58 126.72 571.12 117.54 569.33C107.48 567.38 99.3 564.37 92.82 560.27C86.4 556.19 81.82 551.05 79.05 544.83C80.77 545.3 82.52 545.71 84.25 546.12C85.93 546.51 87.54 546.89 89.04 547.18C98.79 549.07 109.47 549.67 120.69 549.51C90.18 521.29 67.72 486.42 53.55 444.66C33.1 384.44 30.67 313.72 46.45 232.52';
      case 'bubbleSpeech2':
        return 'M451.88 571.97C532.62 540.95 587.85 495.11 617.62 434.47C647.13 373.87 647.48 305.67 618.52 230.18C586.18 145.84 532.1 88.17 456.35 56.94C380.43 25.75 296.65 27.78 204.9 63.05C119.16 95.98 60 142.86 27.03 203.79C-5.72 264.67 -8.69 332.89 18.01 408.78C30.94 438.43 52.78 474.1 89.7 515.41C94.47 521.01 99.38 526.3 104.43 531.35C105.99 541.8 108.18 551.62 111.51 560.29C115.15 569.78 119.49 577.26 124.61 582.88C129.69 588.45 135.49 592.06 142.01 593.69C141.25 592.09 140.57 590.46 139.86 588.83C139.2 587.27 138.54 585.74 138.02 584.34C134.48 575.14 132.05 564.8 130.28 553.8C163.02 578.77 200.88 594.75 243.99 601.43C306.19 611.06 375.5 601.32 451.88 571.97';
      default:
        return 'M1183 0C1200.67 0 1215 14.33 1215 32C1215 73.35 1215 427.66 1215 469.01C1215 485.2 1202.9 498.84 1186.82 500.78C1071.72 514.62 150.92 625.33 35.82 639.17C18.27 641.28 2.34 628.76 0.23 611.22C0.08 609.95 0 608.68 0 607.4C0 552.21 0 87.19 0 32C0 14.33 14.33 0 32 0C262.2 0 1067.9 0 1183 0Z';
    }
  };

  const imageExtension = useCallback(() => {
    let extension = '';
    const canvas = document.createElement('canvas');
    const contentType =
      image && image.file && image.file.contentType
        ? image.file.contentType
        : false;

    switch (contentType) {
      case 'image/png':
        extension = '&fm=png';
        break;
      case 'image/jpeg':
        extension = '&fm=jpg&fl=progressive';
        break;
      default:
        extension = '';
    }

    if (canvas.getContext && canvas.getContext('2d')) {
      return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
        ? '&fm=webp'
        : extension;
    }

    return extension;
  }, [image]);

  const resizeUrl = useCallback(() => {
    let src = '';
    const limit = image.resize && image.resize.width;
    const size = () => {
      if (xxl) {
        return limit < 1550 ? limit : 1550;
      } else if (xl) {
        return limit < 1350 ? limit : 1350;
      } else if (l) {
        return limit < 1225 ? limit : 1225;
      } else if (m) {
        return limit < 969 ? limit : 969;
      } else if (s) {
        return limit < 728 ? limit : 728;
      } else {
        return limit < 480 ? limit : 480;
      }
    };

    if (
      image.file &&
      image.file.url &&
      image.resize &&
      image.resize.aspectRatio
    ) {
      src = `${image.file.url}?w=${size()}&h=${Math.round(
        size() / image.resize.aspectRatio
      )}&q=75&fit=fill${imageExtension()}`;
    } else if (image.file && image.file.url) {
      src = `${image.file.url}?w=${size()}&q=75&fit=fill${imageExtension()}`;
    } else if (image.fluid) {
      src = image.fluid.src;
    }

    if (img.current.src !== src) {
      img.current.src = src;
      img.current.onload = () => {
        setLoading(false);
      };
      setImageUrl(img.current.src);
    }
  }, [image, imageExtension, s, m, l, xl, xxl]);

  useEffect(() => {
    let src = '';
    const initialSize = 200;

    if (resize) {
      if (
        image.file &&
        image.file.url &&
        image.resize &&
        image.resize.aspectRatio
      ) {
        src = `${image.file.url}?w=${initialSize}&h=${Math.round(
          initialSize / image.resize.aspectRatio
        )}&q=30&fit=fill${imageExtension()}`;
      } else if (image.file && image.file.url) {
        src = `${
          image.file.url
        }?w=${initialSize}&q=30&fit=fill${imageExtension()}`;
      } else if (image.fluid) {
        src = image.fluid.src;
      }

      setLoading(true);
      setImageUrl(img.current.src);
      img.current.src = src;
      img.current.onload = () => {
        resizeUrl();
      };
    } else {
      if (image.fluid) {
        src = image.fluid.src;
      } else if (image.resize) {
        src = `${image.resize.src}${imageExtension()}`;
      } else {
        src = image.file.url;
      }

      setImageUrl(img.current.src);
      img.current.src = src;
    }
  }, [image, resize, imageExtension, resizeUrl]);

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewbox().value}
      preserveAspectRatio="xMidYMid slice"
      role="img"
      aria-labelledby={
        image.description ? `title${id} description${id}` : `title${id}`
      }
      {...rest}
    >
      <title id={`title${id}`}>{image.title}</title>

      {image.description && (
        <desc id={`description${id}`}>{image.description}</desc>
      )}

      <defs>
        {gradient === 'heroMobile' && (
          <linearGradient
            id={`gradient${id}`}
            x1="0%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop offset="10%" stopColor="rgba(0, 0, 0, 0.9)" />
            <stop offset="80%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        {gradient === 'heroDesktop' && (
          <linearGradient id={`gradient${id}`}>
            <stop offset="35%" stopColor="rgba(0, 0, 0, 0.5)" />
            <stop offset="80%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        {gradient === 'top' && (
          <linearGradient
            id={`gradient${id}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="rgba(0, 0, 0, 0.6)" />
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        {gradient === 'right' && (
          <linearGradient
            id={`gradient${id}`}
            x1="100%"
            y1="0%"
            x2="0%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(0, 0, 0, 0.6)" />
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        {gradient === 'bottom' && (
          <linearGradient
            id={`gradient${id}`}
            x1="0%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(0, 0, 0, 0.6)" />
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        {gradient === 'left' && (
          <linearGradient
            id={`gradient${id}`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(0, 0, 0, 0.6)" />
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)" />
          </linearGradient>
        )}

        <clipPath id={`clip${id}`}>
          <path d={path()} />
        </clipPath>

        <filter id={`filter${id}`} x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
        </filter>
      </defs>

      <g clipPath={`url(#clip${id})`}>
        {backgroundColor && (
          <rect fill={backgroundColor} width="100%" height="100%" />
        )}

        <image
          y="0"
          x="0"
          height="100%"
          width="100%"
          href={
            resize && image.resize
              ? imageUrl
              : image.resize
              ? `${image.resize.src}${imageExtension()}`
              : image.fluid.src
          }
          {...(loading ? { filter: `url(#filter${id})` } : '')}
        />

        {gradient && (
          <rect fill={`url(#gradient${id})`} width="100%" height="100%" />
        )}
      </g>
    </Svg>
  );
};

export default Mask;
