/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use-media';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Container from '../Container';
import Theme from '../../theme';
import { BreadcrumbWrapper } from './styles';

const Breadcrumb = ({ breadcrumb, label, showBreadcrumbs }) => {
  const [newBreadcrumb, setNewBreadcrumb] = useState();
  const isMobile = useMedia({
    maxWidth: Theme.breakpoints.m,
  });

  useEffect(() => {
    if (breadcrumb) {
      const excludedPaths = ['/co%C3%B6peratie', '/cooperatie', '/'];
      const constructedBreadcrumb = {
        location: breadcrumb.location,
        crumbs: [],
      };

      breadcrumb.crumbs.map((crumb) => {
        const resultCrumb = { ...crumb };
        const currentLabel = crumb.crumbLabel.replace(/-/g, ' ');
        const capitalLabel =
          currentLabel.charAt(0).toUpperCase() + currentLabel.slice(1);
        const isExcludedItem = excludedPaths.some(
          (path) => resultCrumb.pathname === path
        );

        resultCrumb.crumbLabel = capitalLabel;
        resultCrumb.show = !isExcludedItem;

        constructedBreadcrumb.crumbs.push(resultCrumb);

        return resultCrumb;
      });

      if (isMobile) {
        setNewBreadcrumb({
          crumbs: [
            constructedBreadcrumb.crumbs[
              constructedBreadcrumb.crumbs.length - 2
            ],
          ],
        });
      } else {
        setNewBreadcrumb(constructedBreadcrumb);
      }
    }
  }, [isMobile, breadcrumb]);

  return (
    <>
      {newBreadcrumb && newBreadcrumb.crumbs && (
        <BreadcrumbWrapper show={showBreadcrumbs}>
          <Container>
            {showBreadcrumbs && (
              <ol>
                {newBreadcrumb &&
                  newBreadcrumb.crumbs.map(
                    ({ pathname, crumbLabel, show }, index) => (
                      <React.Fragment key={pathname}>
                        {show ? (
                          <li>
                            {!isMobile ? (
                              newBreadcrumb.crumbs.length === index + 1 ? (
                                <span>{label ?? crumbLabel}</span>
                              ) : (
                                <AniLink fade="true" duration={2} to={pathname}>
                                  {crumbLabel}
                                </AniLink>
                              )
                            ) : (
                              <AniLink fade="true" duration={2} to={pathname}>
                                {crumbLabel}
                              </AniLink>
                            )}
                          </li>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    )
                  )}
              </ol>
            )}
          </Container>
        </BreadcrumbWrapper>
      )}
    </>
  );
};

export default Breadcrumb;
