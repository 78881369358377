import React from 'react';
import ImageMask from '../ImageMask/ImageMask';
import { formatSlug } from '../../utils';
import { ArrowRight } from '../../images/svg/Icons';

import {
  Item,
  ImageWrapper,
  Date,
  Label,
  Title,
  Description,
  Button,
} from './styles';

const ArticleItem = ({
  __typename,
  id,
  image,
  imageLarge,
  label,
  category,
  createdAt,
  date,
  title,
  description,
  slug,
  link,
  linkText,
  ...rest
}) => (
  <Item {...rest}>
    {image && (
      <ImageWrapper
        type={__typename !== 'ContentfulArticlePage' ? 'horizontal' : 'default'}
      >
        <ImageMask
          image={__typename !== 'ContentfulArticlePage' ? image : imageLarge}
          variant={
            __typename !== 'ContentfulArticlePage' ? 'horizontal' : 'default'
          }
        />
      </ImageWrapper>
    )}

    {(label || category) && <Label>{category ?? label}</Label>}

    {(date || createdAt) && rest?.hideDate !== true && (
      <Date>{date ?? createdAt}</Date>
    )}

    {title && <Title>{title}</Title>}

    {description && <Description>{description}</Description>}

    {link && (
      <Button
        fade
        duration={2}
        to={formatSlug(link.slug)}
        target={formatSlug(link.slug).startsWith('http') ? '_blank' : '_self'}
        rel={
          formatSlug(link.slug).startsWith('http') ? 'noopener noreferrer' : ''
        }
      >
        {linkText ? (
          <>
            {linkText} <ArrowRight title={linkText} />
          </>
        ) : (
          <ArrowRight />
        )}
      </Button>
    )}

    {slug && (
      <Button
        fade
        duration={2}
        to={formatSlug(slug)}
        target={formatSlug(slug).startsWith('http') ? '_blank' : '_self'}
        rel={formatSlug(slug).startsWith('http') ? 'noopener noreferrer' : ''}
      >
        Meer info <ArrowRight title={linkText} />
      </Button>
    )}
  </Item>
);

export default ArticleItem;
