import styled from 'styled-components';
import Settings from '../../settings';

export const BreadcrumbWrapper = styled.div`
  position: relative;
  display: block;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  margin-top: 28px;
  min-height: 38px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    border-top: 1px solid #e3e3e3;
    border-bottom: ${({ show }) => (show ? '1px solid #e3e3e3' : '0')};
  }

  ol {
    position: relative;
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      margin: ${() =>
        Settings.headerLogoOverflowMode === 'none' ? '0' : '0 0 0 140px'};
    }

    li {
      position: relative;
      display: inline-block;
    }

    a,
    span {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      color: ${(props) => props.theme.colors.greyLight};
      text-decoration: none;
      font-size: 14px;

      &::before {
        content: '\\2190';
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 20px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        padding: 10px 15px;

        &::before {
          content: '\\2039';
          font-size: 14px;
        }
      }
    }

    a {
      &:hover,
      &:focus {
        color: ${(props) => props.theme.colors.brand};
      }
    }
  }
`;
