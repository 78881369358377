import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import typography from '../../typography';

export const Card = styled.article`
  position: relative;
  display: block;
  width: 327px;
  min-width: 327px;
  max-width: 357px;
  padding: 24px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    min-width: 357px;
    width: 357px;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 25px;
`;

export const Number = styled.span`
  ${typography.footnote};
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey};
`;

export const Title = styled.h3`
  ${typography.h6};
  margin-top: 0;
  margin-bottom: 12px;
`;

export const NewLabel = styled.div`
  position: absolute;
  top: 53px;
  right: 42px;
  z-index: 10;

  .new-label-background {
    width: 86px;
    height: auto;
  }
`;

export const NewLabelText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-family: AdelleRegular;
  font-size: 18px;
  margin-top: -4px;
  color: ${(props) => props.theme.colors.black};
`;

export const Button = styled(AniLink)`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Screenreader = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
`;

export const StyledImg = styled.img`
  width: 100%;
`;
