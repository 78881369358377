import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';

const Picture = ({ image, ...rest }) => {
  const [props, setProps] = useState(false);

  useEffect(() => {
    if (image.fixed) {
      setProps({
        fixed: image.fixed,
        objectFit: 'cover',
        objectPosition: '50% 50%',
        ...rest,
      });
    } else if (image.fluid) {
      setProps({
        fluid: image.fluid,
        ...rest,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <>
      {props && (
        <Img
          key={image.id}
          alt={image.title}
          title={image.description ? image.description : image.title}
          loading="lazy"
          {...props}
        />
      )}
    </>
  );
};

export default Picture;
